@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-ExtraLightIt.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-Light.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-It.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-SemiboldIt.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-BoldIt.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-BlackIt.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./SourceSansPro-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
