/*pagination styles*/
.wrapper_pagination {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #23335B;
}
.ant-pagination-item {
  border: 0;
  background: #1C9CE4;
  border-radius: 5px;
}
.ant-pagination-item:hover {
  border: 1px solid #1C9CE4;
  background: none;
}
.ant-pagination-item a {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #23335B;
}
.ant-pagination-item-active {
  background-color: #1C9CE4!important;
}
.ant-pagination-item-active:hover {
  background-color: #FFFFFF!important;
}
.ant-pagination-item-active a {
  color: #FFFFFF!important;
}
.ant-pagination-item-active a:hover {
  color: #1C9CE4!important;
}
.ant-pagination-prev,
.ant-pagination-next{
  height: 27px!important;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  right: 0;
}
.ant-select-selection-item{
  padding-right: 0 !important;
}
