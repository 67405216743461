.ant-table.ant-table-small tr {
  height: 61px;
}
.ant-table-row {
  height: 61px;
}
.ant-table-tbody {
  font-family: 'Source Sans Pro', sans-serif!important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #23335B;
}
.ant-table-container {
  min-width: 230px;
  max-width: 100%;
  min-height: 440px;
  overflow: scroll;
  white-space: nowrap;
}
.ant-table-column-sorters-with-tooltip {
  text-align: center;
}
/* .ant-table-pagination-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.ant-pagination-item {
  border: 0;
  background: #1C9CE4;
  border-radius: 5px;
}
.ant-pagination-item:hover {
  border: 1px solid #1C9CE4;
  background: none;
}
.ant-pagination-item a {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #23335B;
}
.ant-pagination-item-active a {
  color: #FFFFFF!important;
}
.ant-pagination-item-active a:hover {
  color: #1C9CE4!important;
}
.ant-pagination-prev,
.ant-pagination-next {
  height: 27px!important;
  line-height: 24px;
}
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 0 8px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  width: 100px;
}
.ant-pagination-options {
  position: absolute;
  left: 0;
  width: fit-content;
} */
.custom-row {
  background-color: #cce5ff;
}
th.custom-row {
  background-color: #b7cee5!important;
}

/* Antd Filter menu */
.ant-table-filter-dropdown {
  box-shadow: 3px 3px 0 #8A93A6;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 280px;
}
.ant-dropdown input {
  border: 1px solid #8A93A6;
}

/* Btns */
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: flex-end;
}
.ant-btn.ant-btn-link.ant-btn-sm:first-of-type {
  margin-right: 5px;
}
.ant-btn.ant-btn-link.ant-btn-sm > span {
  /* visibility: hidden; */
  color: transparent;
  background: no-repeat center url('../../theme/image/svg/Cross-sm.svg');
}
.ant-btn.ant-btn-link.ant-btn-sm > span:after {
  /* content: 'X'; */
  /* content: url('../../theme/image/svg/Cross-sm.svg');
  visibility: visible; */
}
.ant-btn {
  font-size: 12px;
  border-radius: 22px;
  width: 39px;
  border: 1px solid #8A93A6;
  /* padding: 0 15px; */
}
.ant-btn.ant-btn-primary {
  border: 1px solid #1890ff;
}
.ant-btn.ant-btn-primary:disabled {
  border: 1px solid #8A93A6;
}
.ant-btn:hover, .ant-btn:focus {
  border: 1px solid #40a9ff;
}
.ant-btn-link {
  border: 1px solid transparent;
}
.ant-btn-link:hover, .ant-btn-link:focus, .ant-btn-link:active {
  border: 1px solid transparent;
}
.ant-dropdown-menu-item {
  font-size: 12px;
}

/* Columns */
.ant-column-name,
.ant-column-email,
.ant-column-procedure,
.ant-column-date-of-birth-or-surgery,
.ant-column-last-activity,
.ant-column-nhs-number {
  overflow-wrap: normal !important;
  white-space: nowrap;
  overflow: hidden;
}
.ant-column-name {
  font-size: 16px;
  font-weight: 600;
  color: #23335B;
}
.ant-column-date-of-birth-or-surgery {
  font-size: 13px;
  font-weight: 600;
  color: #8A93A6;
  text-align: center;
}
.ant-column-email,
.ant-column-nhs-number,
.ant-column-hospital-name,
.ant-column-last-activity {
  font-size: 14px;
  font-weight: 400;
  color: #23335B;
}
.ant-column-procedure {
  font-size: 14px;
  font-weight: 600;
  color: #23335B;
  text-align: center;
}
.ant-column-resend-code,
.ant-column-last-activity {
  text-align: center;
}
.search_picker input {
  width: 190px;
  height: 33px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #333;
  margin: 0;
}
.search_picker {
  margin-bottom: 15px;
}
.ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-container {
  margin-top: 8px;
  height: fit-content!important;
  min-height: fit-content!important;
}


@media screen and (max-width: 640px) {
  .ant-column-name {
    font-size: 12px;
  }
}
