@value light_grey from '../../theme/variables.module.css';
@value main_white from '../../theme/variables.module.css';
@value main_blue from '../../theme/variables.module.css';
@value main_red from '../../theme/variables.module.css';

.feedback__container,
.feedback__container-close {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 450px;
  min-width: 320px;
  height: 100%;
  background-color: light_grey;
  padding: 20px 40px;
  z-index: 1101;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  overflow-y: auto;
}
/* The element to apply the animation to */
.feedback__container {
  right: 0;
  animation-name: toFullScreen;
  animation-duration: 1s;
}
.feedback__container-close {
  right: -450px;
  animation-name: closeFullScreen;
  animation-duration: 1s;
}
/* The animation code */
@-webkit-keyframes toFullScreen {
  from {right: -450px;}
  to {right: 0;}
}
@keyframes toFullScreen {
  from {right: -450px;}
  to {right: 0;}
}
@-webkit-keyframes closeFullScreen {
  from {right: 0;}
  to {right: -450px;}  
}
@keyframes closeFullScreen {
  from {right: 0;}
  to {right: -450px;}
}
.feedback__title-cont {
  display: flex;
}
.feedback__title {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}
.feedback__close {
  position: absolute;
  right: 15px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  -webkit-transition: all 0.5s ease, box-shadow 0.3s ease;
  transition: all 0.5s ease, box-shadow 0.3s ease;
}
.feedback__close:hover {
  box-shadow: 0 0 2px 0 rgb(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

.feedback__icon {
  position: fixed;
  top: calc(100vh - 70px);
  top: calc((var(--vh, 1vh) * 100) - 70px);
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: main_white;
  font-size: 20px;
  font-weight: 700;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(28, 156, 228, 0.8);
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  animation-name: feedback__icon;
  animation-duration: 1s;
  z-index: 100;
}
@-webkit-keyframes  feedback__icon {
  from {
    width: 45px;
    height: 45px;
    top: calc(100vh - 70px);
    top: calc((var(--vh, 1vh) * 100) - 70px);
    opacity: 0;
  }
  to {
    width: 45px;
    height: 45px;
    top: calc(100vh - 70px);
    top: calc((var(--vh, 1vh) * 100) - 70px);
    opacity: 1;
  }
}
@keyframes feedback__icon {
  from {
    width: 45px;
    height: 45px;
    top: calc(100vh - 70px);
    top: calc((var(--vh, 1vh) * 100) - 70px);
    opacity: 0;
  }
  to {
    width: 45px;
    height: 45px;
    top: calc(100vh - 70px);
    top: calc((var(--vh, 1vh) * 100) - 70px);
    opacity: 1;
  }
}
.feedback__icon:hover,
.feedback__close:hover,
.feedback__delete-icon:hover {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.feedback__icon:hover {
  background-color: main_blue;
}
.feedback__descr {
  margin-bottom: 10px;
  text-align: justify;
}
.feedback__call-descr {
  text-align: center;
}
.feedback__call-descr a {
  text-decoration: underline;
}

/*FORM*/
.form_wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cell {
  width: 48%;
  min-width: 150px;
}
.row input {
  width: 100%;
}
.row div[class*="input-type-file"] {
  width: 100%;
}
.attachments-row {
  display: flex;
  width: 100%;
  padding: 3px 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.attachments-row:hover {
  border: 1px solid main_blue;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.attachments-row:first-of-type {
  margin-top: 10px;
}
.feedback__file-name {
  width: 95%;
  padding-right: 5px;
  overflow: hidden;
  word-wrap: break-word;
}
.feedback__delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.feedback__delete-all-cont {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.feedback__delete-all {
  color: main_blue;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.feedback__delete-all:hover {
  text-shadow: 1px 1px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.feedback__error {
  color: main_red;
  text-align: center;
  padding: 0 4px;
  font-size: 13px;
  margin-top: 10px;
}
.btn__submit {
  width: 270px;
  margin: 20px auto 20px auto;
  font-size: 20px;
}

/*ADAPTIVE*/
@media screen and (max-width: 400px) {
  .row {
    flex-direction: column;
  }
  .cell {
    width: 100%;
  }
}

