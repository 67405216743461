.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  text-align: center;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: #23335B;
  color: transparent;
  cursor: pointer;
  display: block;
  width: 20px;
  height: 7px;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.button__bar li.slick-active button {
  background-color: #1C9CE4;
  width: 40px;
}

/* .button__bar li button:focus, */
.button__bar li button:hover {
  background: #F8F9FD;
  outline: 0;
}
