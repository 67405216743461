@value main_black from '../../../theme/variables.module.css';
@value main_red from '../../../theme/variables.module.css';

.search-container {
  width: 100%;
  margin-bottom: 20px;
}
.modal__manage-dep-title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
}
.modal__manage-dep-input {
  width: 100%;
  height: 50px;
  margin-bottom: 12px;
}
.modal__manage-dep-err-cont {
  width: 420px;
  min-height: 60px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: main_red;
  font-size: 13px;
  margin: 0 auto;
}
.modal__manage-dep-btn-cont {
  display: flex;
}
.modal__manage-dep-btn {
  min-width: 200px;
}
.modal__manage-dep--label,
.modal__manage-dep--description {
  width: 420px;
  color: main_black;
}
.modal__manage-dep--label {
  font-weight: 600;
  margin: 0 auto;
  font-size: 16px;
}
.modal__manage-dep--description {
  margin: 0 auto -15px auto;
  font-size: 14px;
}
.modal__manage-dep--input-cont {
  width: fit-content;
  position: relative;
  margin: 0 auto;
}
.modal__manage-form {
  max-height: 500px;
  overflow-y: auto;
}
.delete-content {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: main_black;
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 560px) {
  .manage_departments__container {
    width: 100%;
  }
  .modal__manage-dep {
    width: 95%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .modal__manage-dep-btn-cont {
    flex-direction: column;
  }
}
@media screen and (max-width: 540px) {
  .modal__manage-dep--label,
  .modal__manage-dep--description,
  .modal__manage-dep-err-cont,
  .modal__manage-dep--input-cont {
    width: 100%;
  }
}
