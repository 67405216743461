input:hover {
  border: 1px solid #1c9ce4 !important;
}
input[type=tel] {
  border: none !important;
}
input:disabled {
  background-color: transparent!important;
}
/* input::placeholder {
  opacity: 0.5;
} */
/* input::-webkit-input-placeholder {
  opacity: 0.5;
}
input:-moz-placeholder {
  opacity: 0.5;
}
input::-ms-input-placeholder {
  opacity: 0.5;
} */
