@import "./fonts/fonts.css";

html {
  box-sizing: border-box; 
  /* -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; */
}

/* *,
*::before,
*::after {
    box-sizing: inherit;
} */

body {
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-size: 16px;
  background-color: #FFFFFF;
  /* background-color: #F8F9FD; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #23335B;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button:hover,
button:focus {
  border: transparent;
  cursor: pointer;
}

button:focus {
  outline: none;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #FCFBFE;
  box-shadow: inset 0 0 3px rgba(179, 179, 248, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #9BA1AE;
  border-radius: 4px;
  width: 6px;
  height: 32px;
}
.Toastify__toast-container--top-right {
  top: 7em;
}


/* For antd pagination dropdown - items per page */
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  margin: 0 auto;
  text-align: center;
}
.ant-select-item-option {
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  margin: 0 auto !important;
  background-color: #e6f7ff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  margin: 0 auto;
  background-color: #e6f7ff;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
  margin: 0 auto !important;
}
.ant-table-pagination-right,
.ant-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ant-pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px!important;
  width: 28px;
  background-color: #EEEEEE!important;
  border: 0;
  transition: all 300ms ease-in-out;
  /* background: #1C9CE4; */
}
.ant-pagination-item:nth-child(2) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-pagination-item:nth-last-child(3) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-pagination-item:hover {
  border: 1px solid #1C9CE4;
  /* background: none; */
  background-color: #EEEEEE!important;
}
.ant-pagination-item a {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #23335B;
  transition: all 350ms ease-in-out;
}
.ant-pagination-item-active {
  width: 32px!important;
  height: 32px!important;
  border-radius: 4px;
  background-color: #1C9CE4!important;;
}
.ant-pagination-item-active a {
  color: #FFFFFF!important;
}
.ant-pagination-item-active:hover a {
  color: #1C9CE4!important;
}
.ant-pagination-prev {
  margin-right: 5px!important;
}
.ant-pagination-next {
  margin-left: 5px!important;
}
.ant-pagination-prev,
.ant-pagination-next {
  border-radius: 4px;
  height: 28px!important;
  line-height: 24px;
  background-color: #EEEEEE;
}
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 0 8px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  width: 100px;
}
.ant-pagination-options {
  position: absolute;
  left: 0;
  width: fit-content;
}


/*EXPORT ICONS*/
.pdf-icon {
    font-size: 26px;
    color: #08c;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}
.pdf-icon:first-child {
    margin-bottom: 10px;
}
.pdf-icon:hover {
    transform: scale(1.2);
}
.screen-to-PDF {
    width: 100%;
}

.breadCrumbs-item {
  color: #1C9CE4;
  cursor: pointer;
}
.breadCrumbs-item:hover {
  text-decoration: underline;
}
.breadcrumbAntd-item {
  margin-bottom: 20px;
  min-width: 300px;
}
.breadcrumbAntd-item span,
.breadcrumbAntd-item span a {
  color: #23335B;
}

/*MODALS*/
    /*BUTTON CLOSE*/
.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  transition: 0.35s ease, box-shadow 0.35s ease;
}
.btn-close:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 0 rgb(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  transition: 0.35s ease, box-shadow 0.35s ease;
}
.btn-close svg path {
  transition: 0.35s ease, box-shadow 0.35s ease;
}
.btn-close:hover svg path {
  transition: 0.35s ease, box-shadow 0.35s ease;
  fill: #ffffff
}

/* customize antd Tooltip */
.ant-tooltip {
  max-width: 314px;
  z-index: 10000
}
.ant-tooltip-inner,
.ant-popover-inner {
  background-color: #F8F9FD;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, .25);
  color: #000000;
  font-size: 14px;
}
.ant-tooltip-inner {
  padding: 15px;
}
.ant-tooltip-arrow-content {
  background-color: #F8F9FD;
}

/* Toastify */
/* .Toastify__toast-body {
  word-break: break-all;
} */

/* External contant Editor */
.rdw-link-modal {
  min-height: 237px;
}
.rdw-link-modal-target-option {
  display: flex;
  align-items: center;
}
input[type='checkbox'] {
  position: static;
}
.rdw-editor-main {
  min-height: 100px;
}
